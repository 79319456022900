import Splide from '@splidejs/splide';
import {Grid} from '@splidejs/splide-extension-grid';
import 'tw-elements';

/*————————————————————————————————————————————————————*\
    ●❱ UTILS
\*————————————————————————————————————————————————————*/
fetch(window.location.href, {
    method: "GET",
    headers: {
        "X-Screen-Width": window.innerWidth
    }
});



function beforeMidnight() {
    let mid = new Date(),
        ts = mid.getTime();
    mid.setHours(24, 0, 0, 0);
    return Math.floor((mid - ts) / 60000);
}

function setCookie(cname, cvalue) {
    'use strict';
    let d = new Date();
    d.setTime(d.getTime() + (beforeMidnight() * 60 * 1000));
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + "; " + expires;
}

function getCookie(cname) {
    'use strict';
    let name = cname + "=";
    let ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

let displayPopupAd = function (elem) {
    let duration = parseInt($(elem).data('duration'), 10);

    if ($(elem).hasClass('modal')) {
        setInterval(function () {
            duration--;
            $('.ads-timer').text(duration + ' seg');
            if (duration <= 0) {
                $(elem).modal('hide');
                $('body').removeClass('overflow-hidden');
                clearInterval(this)
            }
        }, 1000);
        $(elem).modal('show');
    } else {
        setInterval(function () {
            duration--;
            $('.ads-timer').text(duration + ' seg');
            if (duration <= 0) {
                $(elem).addClass('hidden');
                clearInterval(this)
            }
        }, 1000);
        $(elem).removeClass('hidden');
        $('body').removeClass('overflow-hidden');
    }

    // save that user has close the popup
    setCookie('userClosedPopup', true);
};

$(window).on('load', function () {
    if ($('.js-ad-elem').length > 0) {
        if (getCookie('userClosedPopup') !== 'true') {
            displayPopupAd('.js-ad-elem');
        } else {
            $('#popup-ad').remove();
        }
    }

    const isMobile = window.orientation > -1;
    if (isMobile) {
        $(".mobile-widget").each(function (index, widget) {
            const items = $(widget).find(".widget-item");
            items.slice(5).addClass("hidden");
        });
    }
});

/*————————————————————————————————————————————————————*\
    ●❱ SLIDERS
\*————————————————————————————————————————————————————*/
// Slider noticias principales
const main_slider_id = document.querySelector('#main-carousel');
const main_slider_thumb = document.querySelector('#thumbnail-carousel');
if (main_slider_id && main_slider_thumb) {
    let main_news_slider = new Splide('#main-carousel', {
        type: 'fade',
        rewind: true,
        pagination: false,
        arrows: false,
    });

    let thumbnails_news_slider = new Splide('#thumbnail-carousel', {
        fixedWidth: 100,
        fixedHeight: 60,
        gap: 10,
        rewind: true,
        pagination: false,
        isNavigation: true,
        arrows: false,
    });

    main_news_slider.sync(thumbnails_news_slider);

    main_news_slider.on('mounted', function () {
        let activeSlideIndex = main_news_slider.index;
        let thumbnails = document.querySelectorAll('#thumbnail-carousel li');
        let title_main_slider = document.querySelectorAll('#main-carousel li')[activeSlideIndex];
        let data_url = title_main_slider.querySelector('#title_main_slider').getAttribute('data-geturl')

        for (let i = 0; i < thumbnails.length; i++) {
            let thumbnail = thumbnails[i];

            if (i === activeSlideIndex) {
                replaceDivWithAnchor(thumbnail, data_url);
            }
        }
    });

    main_news_slider.on('move', function () {
        let activeSlideIndex = main_news_slider.index;
        let thumbnails = document.querySelectorAll('#thumbnail-carousel li');
        let title_main_slider = document.querySelectorAll('#main-carousel li')[activeSlideIndex];
        let data_url = title_main_slider.querySelector('#title_main_slider').getAttribute('data-geturl');

        for (let i = 0; i < thumbnails.length; i++) {
            let thumbnail = thumbnails[i];

            if (i === activeSlideIndex) {
                replaceDivWithAnchor(thumbnail, data_url);
            }
        }

        for (let i = 0; i < thumbnails.length; i++) {
            let thumbnail = thumbnails[i];

            if (i != activeSlideIndex) {
                replaceAnchorWithDiv(thumbnail);
            }
        }
    });

    main_news_slider.mount();
    thumbnails_news_slider.mount();


}

function replaceDivWithAnchor(thumbnail, url) {
    let linkDiv = thumbnail.querySelector('.link_thumnail');

    if (linkDiv.tagName.toLowerCase() === 'div') {
        let anchor = document.createElement('a');
        anchor.className = linkDiv.className;
        anchor.href = url;
        anchor.innerHTML = linkDiv.innerHTML;

        linkDiv.replaceWith(anchor);
    }
}

function replaceAnchorWithDiv(thumbnail) {
    let linkAnchor = thumbnail.querySelector('.link_thumnail');

    if (linkAnchor.tagName.toLowerCase() === 'a') {
        let div = document.createElement('div');
        div.className = linkAnchor.className;
        div.innerHTML = linkAnchor.innerHTML;

        linkAnchor.replaceWith(div);
    }
}

const morevideo_slider_id = document.querySelector('#more-videos');

if (morevideo_slider_id) {
    // Slider de mas videos bg azul
    let more_videos_slider = new Splide('#more-videos', {
        type: 'loop',
        perPage: 3,
        perMove: 1,
        padding: '9rem',
        focus: 'center',
        pagination: false,
        mediaQuery: 'max',
        breakpoints: {
            1024: {
                perPage: 2,
                perMove: 1,
                padding: '10rem',
                focus: 'center',
            },
            768: {
                perPage: 1,
                perMove: 1,
                padding: '10rem',
                focus: 'center',
            },
            640: {
                perPage: 1,
                perMove: 1,
                padding: '5rem',
                focus: 'center',
            },
            420: {
                perPage: 1,
                perMove: 1,
                padding: '3rem',
                focus: 'center',
            }
        },
    });

    more_videos_slider.mount();
}

const social_slider_id = document.querySelector('#social_slider');

if (social_slider_id) {
    //slider de redes sociales
    let social_slider = new Splide('#social_slider', {
        type: 'loop',
        perPage: 3,
        perMove: 1,
        padding: '18rem',
        focus: 'center',
        pagination: false,
        mediaQuery: 'max',
        breakpoints: {
            1280: {
                perPage: 2,
                padding: '3rem',
                focus: 'center',
            },
            1024: {
                perPage: 3,
            },
            420: {
                perPage: 1,
                padding: '1rem',
                width: 400,
            },
        },
    });

    social_slider.mount();
}

const more_news_id = document.querySelector('#more-news');

if (more_news_id) {
    //slider de mas noticias
    let more_news = new Splide('#more-news', {
        type: 'loop',
        perPage: 3,
        perMove: 1,
        padding: '9rem',
        focus: 'center',
        pagination: false,
        mediaQuery: 'max',
        breakpoints: {
            1024: {
                perPage: 2,
                perMove: 1,
                padding: '10rem',
                focus: 'center',
            },
            768: {
                perPage: 1,
                perMove: 1,
                padding: '10rem',
                focus: 'center',
            },
            640: {
                perPage: 1,
                perMove: 1,
                padding: '5rem',
                focus: 'center',
            },
            420: {
                perPage: 1,
                perMove: 1,
                padding: '3rem',
                focus: 'center',
            },
        },
    });

    more_news.mount();
}

const related_notes_id = document.querySelector('#related-notes');

if (related_notes_id) {
    let related_notes = new Splide('#related-notes', {
        type: 'loop',
        perPage: 3,
        perMove: 1,
        padding: '12rem',
        focus: 'center',
        pagination: false,
        mediaQuery: 'max',
        breakpoints: {
            1280: {
                perPage: 2,
                padding: '10rem',
            },
            1440: {
                perPage: 2,
                padding: '10rem',
            },
            1024: {
                perPage: 2,
                padding: '6rem',
            },
            768: {
                perPage: 1,
                padding: '1rem',
            },
            640: {
                perPage: 1,
                padding: '1rem',
            }
        },
    });

    related_notes.mount();
}

const gallery_images = document.querySelector('#gallery_images');
if (gallery_images) {
    const gallery = new Splide('#gallery_images', {
        type: 'loop',
        perPage: 1,
        perMove: 1,
        grid: {
            // You can define rows/cols instead of dimensions.
            dimensions: [[2, 3]],
            gap: {
                row: '6px',
                col: '6px',
            },
        },
        mediaQuery: 'max',
        breakpoints: {
            768: {
                grid: {
                    dimensions: [[2, 2]],
                }
            },
            420: {
                grid: {
                    dimensions: [[1, 1]],
                }
            }
        },
    })

    gallery.mount({Grid});
}

const gallery_audio = document.querySelector('#gallery_audios');
if (gallery_audio) {
    const gallery = new Splide('#gallery_audios', {
        type: 'loop',
        perPage: 1,
        perMove: 1,
        grid: {
            // You can define rows/cols instead of dimensions.
            dimensions: [[2, 3]],
            gap: {
                row: '6px',
                col: '6px',
            },
        },
        mediaQuery: 'max',
        breakpoints: {
            768: {
                grid: {
                    dimensions: [[2, 2]],
                }
            },
            420: {
                grid: {
                    dimensions: [[1, 1]],
                }
            }
        },
    })

    gallery.mount({Grid});
}

const gallery_videos = document.querySelector('#gallery_videos');
if (gallery_videos) {
    const gallery = new Splide('#gallery_videos', {
        type: 'loop',
        perPage: 1,
        perMove: 1,
        grid: {
            // You can define rows/cols instead of dimensions.
            dimensions: [[2, 3]],
            gap: {
                row: '6px',
                col: '6px',
            },
        },
        mediaQuery: 'max',
        breakpoints: {
            768: {
                grid: {
                    dimensions: [[2, 2]],
                }
            },
            420: {
                grid: {
                    dimensions: [[1, 1]],
                }
            }
        },
    })

    gallery.mount({Grid});
}

const gallery_maps = document.querySelector('#gallery_maps');
if (gallery_maps) {
    const gallery = new Splide('#gallery_maps', {
        type: 'loop',
        perPage: 1,
        perMove: 1,
        grid: {
            // You can define rows/cols instead of dimensions.
            dimensions: [[2, 3]],
            gap: {
                row: '6px',
                col: '6px',
            },
        },
        mediaQuery: 'max',
        breakpoints: {
            768: {
                grid: {
                    dimensions: [[2, 2]],
                }
            },
            420: {
                grid: {
                    dimensions: [[1, 1]],
                }
            }
        },
    })

    gallery.mount({Grid});
}

const slide_detail_images = document.querySelector('#slide_detail_images');
const thumbnail_slide_detail_images = document.querySelector('#thumbnail_slide_detail_images');
const modalImageGallery = document.querySelector('#modal_image_gallery');


if (slide_detail_images && thumbnail_slide_detail_images) {
    // Slider de detalle de galeria de imagenes
    const slide_detail = new Splide('#slide_detail_images', {
        type: 'fade',
        rewind: true,
        pagination: false,
        arrows: false,
    });

    const thumbnail_slide_detail = new Splide('#thumbnail_slide_detail_images', {
        type: 'loop',
        gap: 8,
        rewind: true,
        pagination: false,
        isNavigation: true,
        autoWidth: true,
        focus: 'center',
        perPage: 3,
        perMove: 1,
        breakpoints: {
            600: {
                fixedWidth: 60,
                fixedHeight: 44,
            },
        },
    });

    slide_detail.sync(thumbnail_slide_detail);
    let modal;

    if (modalImageGallery) {
        const modal_gallery = new Splide('#modal_image_gallery', {
            type: 'fade',
            rewind: true,
            pagination: false,
        });
        thumbnail_slide_detail.sync(modal_gallery);

        modal_gallery.on('mounted', () => {
            modal_gallery.root.focus();
            modal_gallery.root.addEventListener('keydown', (event) => {
                if (event.key === 'ArrowLeft' || event.keyCode === 37) {
                    modal_gallery.go('<');
                } else if (event.key === 'ArrowRight' || event.keyCode === 39) {
                    modal_gallery.go('>');
                }
            });
        });

        modal = modal_gallery;
    }

    thumbnail_slide_detail.on('mounted', () => {
        thumbnail_slide_detail.root.focus();
        thumbnail_slide_detail.root.addEventListener('keydown', (event) => {
            if (event.key === 'ArrowLeft' || event.keyCode === 37) {
                thumbnail_slide_detail.go('<');
            } else if (event.key === 'ArrowRight' || event.keyCode === 39) {
                thumbnail_slide_detail.go('>');
            }
        });
    });


    slide_detail.mount();
    if (modalImageGallery) modal.mount();
    thumbnail_slide_detail.mount();


}

let slides = document.querySelectorAll('.splide__slide');
slides.forEach(function (slide) {
    slide.removeAttribute('role');
    slide.removeAttribute('aria-roledescription');
});

/*————————————————————————————————————————————————————*\
    ●❱ MODALS
\*————————————————————————————————————————————————————*/

// modal del galeria de imagenes
const expand = document.querySelector('.fullscreen-img');
if (expand) {
    let tag_img = document.querySelector('.imagepreview');
    expand.addEventListener('click', (e) => {
        e.preventDefault();
        let data_url = expand.getAttribute('data-orimage');
        tag_img.src = data_url;
    });
}

/*————————————————————————————————————————————————————*\
    ●❱ parallax for internal news (IMG)
\*————————————————————————————————————————————————————*/
const story_body = document.querySelector('.story__body.use_parallax');
let adsIsImg = false;
if (story_body) {
    const images = story_body.querySelectorAll('img');
    if (images.length > 0)
        adsIsImg = true;

    for (let i = 0; i < images.length; i++) {
        const img = images[i];
        const section = document.createElement('section');
        const divimg = document.createElement('div');
        const secondDiv = document.createElement('div');
        const imgUrl = img.getAttribute("src");

        section.classList.add('article_fullwidth_image');
        divimg.classList.add('simpleParallax');
        img.classList.add('parallax-image');
        secondDiv.style.backgroundImage = `url(${imgUrl})`;
        secondDiv.classList.add('divimage');

        story_body.insertBefore(section, img);
        section.appendChild(divimg);
        divimg.appendChild(img);
        divimg.insertAdjacentHTML('afterend', secondDiv.outerHTML);
    }

}

/*————————————————————————————————————————————————————*\
    ●❱ PROGRESS READING BAR
\*————————————————————————————————————————————————————*/

function updateProgressBar() {
    const article_detail = document.querySelector('.article_detail');
    if (article_detail) {
        const height_article = article_detail.offsetHeight;
        const scrollTop = document.documentElement.scrollTop;
        const windowHeight = window.innerHeight;
        const scrollPercent = `${(scrollTop / (height_article - windowHeight)) * 100}%`;

        document.querySelector('#progress-reading').style.setProperty('--progress', scrollPercent);
    }
}

document.addEventListener('scroll', updateProgressBar);
window.addEventListener('load', updateProgressBar);

/*————————————————————————————————————————————————————*\
    ●❱ parallax for internal news (code)
\*————————————————————————————————————————————————————*/
window.addEventListener('load', () => {
    if (adsIsImg) {
        const section = document.querySelector('.article_fullwidth_image');
        const secondDiv = document.querySelector('.divimage');
        if (section || secondDiv) {
            let leftOffset = section.offsetLeft;
            let withSection = section.offsetWidth;
            secondDiv.style.backgroundPositionX = `${leftOffset}px`;
            if (withSection > 0) {
                secondDiv.style.backgroundSize = `${withSection}px`;
            }
        }
    } else {
        let div_child = document.querySelector('.story__body.use_parallax div');
        if (div_child) {
            let frame = document.querySelector('.story__body div iframe');
            if (frame) {
                let frameDoc = frame.contentWindow.document;
                let linkImage = frameDoc.querySelector('#google_image_div a img');
                let link_ads = frameDoc.querySelector('#google_image_div a');

                if (linkImage) {
                    let img_a = link_ads.querySelector('img');
                    div_child.appendChild(link_ads);
                    link_ads.classList.add('article_fullwidth_image');
                    const secondDiv = document.createElement('div');
                    const imgUrl = link_ads.querySelector('img').getAttribute("src");

                    img_a.classList.add('parallax-image');
                    secondDiv.style.backgroundImage = `url(${imgUrl})`;
                    secondDiv.classList.add('divimage');

                    div_child.querySelector('a').appendChild(secondDiv);

                    frame.classList.add('hidden');
                }

            }

            let leftOffset = div_child.offsetLeft;
            let withSection = div_child.offsetWidth;
            document.querySelector('.divimage').style.backgroundPositionX = `${leftOffset}px`;
            if (withSection > 0) {
                document.querySelector('.divimage').style.backgroundSize = `${withSection}px`;
            }
        }
    }
});

window.addEventListener("resize", () => {
    const section = document.querySelector('.article_fullwidth_image');
    const secondDiv = document.querySelector('.divimage');
    const img_ad_code = document.querySelector('#google_image_div .img_ad');

    if (section) {
        let leftOffset = section.offsetLeft;
        let withSection = section.offsetWidth;
        secondDiv.style.backgroundPositionX = `${leftOffset}px`;
        if (withSection > 0) {
            secondDiv.style.backgroundSize = `${withSection}px`;

            if (img_ad_code) {
                img_ad_code.style.width = `${withSection}px`;
            }
        }

    }
});

/*————————————————————————————————————————————————————*\
    ●❱ Sent Contact Form
\*————————————————————————————————————————————————————*/
const form_contact = document.querySelector('#contact-form');
const icon_check = document.querySelector('#icon_check_form');
const icon_error = document.querySelector('#icon_error_form');
const msg_container = document.querySelector('.ct-form__message-text');
if (form_contact) {
    form_contact.addEventListener('submit', (e) => {
        e.preventDefault();
        const form = e.target;
        if (form.classList.contains('ct-form')) {
            // Prevent the default form submit
            // e.preventDefault();

            const ele_message = form.querySelector('.ct-form__message')
            const formData = new FormData(form);
            const payload = {};
            formData.forEach(function (value, key) {
                payload[key] = value;
            });

            // Post data using the Fetch API
            fetch(form.action, {
                method: form.method,
                headers: {
                    'Accept': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest',
                    'X-CSRFToken': e.target.csrfmiddlewaretoken.value,
                },
                body: formData
            }).then(response => {
                return response.json() //Convert response to JSON
            }).then(data => {
                if (data.sent) {
                    form.querySelector('.ct-form__form-wrapper').style.display = "none";
                    msg_container.innerHTML = data.msg;
                    ele_message.style.display = "flex";
                    icon_check.style.display = 'block';
                } else {
                    msg_container.innerHTML = data.msg;
                    ele_message.style.display = "flex";
                    icon_error.style.display = 'block';
                    form.querySelector('.ct-form__form-wrapper').style.display = "none";
                }
            }).then(
                setTimeout(() => {
                    icon_check.style.display = 'none';
                    icon_error.style.display = 'none';
                    ele_message.style.display = "none";
                    e.target.reset();
                    form.querySelector('.ct-form__form-wrapper').style.display = "grid";
                }, 5000)
            )
        }
    });
}

/*————————————————————————————————————————————————————*\
    ●❱ Mobile Social Network
\*————————————————————————————————————————————————————*/
const shareIcon = document.getElementById("share-icon");
const socialNetworks = document.querySelectorAll(".sticky-bar-mobile a");
if (shareIcon) {
    shareIcon.addEventListener("click", () => {
        shareIcon.classList.toggle("sticky-bar-open");
        socialNetworks.forEach(socialNetwork => {
            socialNetwork.classList.toggle("show-icons");
        });

    })
}


/*————————————————————————————————————————————————————*\
    ●❱ STICKY
\*————————————————————————————————————————————————————*/
$(document).ready(function () {
    var $sectionNav = $('.section-nav');
    var $stickyNav = $('.sticky-nav');

    if ($sectionNav.length && $stickyNav.length) {
        var stickyNavBottom = $sectionNav.offset().top + $sectionNav.outerHeight(true);
        var isThrottling = false;

        var stickyNav = function() {
            var scrollTop = $(window).scrollTop();
            if (scrollTop > stickyNavBottom) {
                $stickyNav.addClass('show-sticky');
            } else {
                $stickyNav.removeClass('show-sticky');
            }
        };

        stickyNav();

        $(window).scroll(function () {
            if (!isThrottling) {
                isThrottling = true;
                requestAnimationFrame(function () {
                    stickyNav();
                    isThrottling = false;
                });
            }
        });
    }
});

$(document).ready(function () {
    var $stickyNav = $('.stick-nav');
    var $stickyNavTop = $('.sticky-nav-top');

    if ($stickyNav.length && $stickyNavTop.length) {
        var stickyNavBottom = $stickyNav.offset().top + $stickyNav.outerHeight(true);
        var isThrottling = false;

        var stickyNav = function() {
            var scrollTop = $(window).scrollTop();
            if (scrollTop > stickyNavBottom) {
                $stickyNavTop.addClass('show-sticky');
            } else {
                $stickyNavTop.removeClass('show-sticky');
            }
        };

        stickyNav();

        $(window).scroll(function () {
            if (!isThrottling) {
                isThrottling = true;
                requestAnimationFrame(function () {
                    stickyNav();
                    isThrottling = false;
                });
            }
        });
    }
});


/*————————————————————————————————————————————————————*\
    ●❱ PLAYER
\*————————————————————————————————————————————————————*/
if ($('#video-detail').length > 0) {
    const protocolAndDomain = `${window.location.protocol}//${window.location.host}`;

    FWDUVPUtils.onReady(function () {

        new FWDUVPlayer({
            //main settings
            instanceName: "player",
            parentId: "video-detail",
            playlistsId: "playlist",
            mainFolderPath: `${protocolAndDomain}/static/player`,
            skinPath: "minimal_skin_dark",
            displayType: "responsive",
            useVectorIcons: "no",
            fillEntireVideoScreen: "no",
            fillEntireposterScreen: "no",
            goFullScreenOnButtonPlay: "no",
            playsinline: "yes",
            initializeOnlyWhenVisible: "no",
            useHEXColorsForSkin: "no",
            normalHEXButtonsColor: "#FF0000",
            selectedHEXButtonsColor: "#000000",
            facebookAppId: "213684265480896",
            googleAnalyticsTrackingCode: "",
            useResumeOnPlay: "no",
            useDeepLinking: "no",
            showPreloader: "no",
            preloaderBackgroundColor: "#000000",
            preloaderFillColor: "#FFFFFF",
            addKeyboardSupport: "yes",
            autoScale: "yes",
            showButtonsToolTip: "no",
            stopVideoWhenPlayComplete: "no",
            playAfterVideoStop: "no",
            autoPlay: "no",
            loop: "no",
            shuffle: "no",
            showErrorInfo: "no",
            maxWidth: 1170,
            maxHeight: 659,
            volume: .8,
            buttonsToolTipHideDelay: 1.5,
            backgroundColor: "#eeeeee",
            videoBackgroundColor: "#000000",
            posterBackgroundColor: "#000000",
            buttonsToolTipFontColor: "#FFFFFF",
            //playlists/categories settings
            showPlaylistsSearchInput: "no",
            usePlaylistsSelectBox: "no",
            showPlaylistsButtonAndPlaylists: "no",
            showPlaylistsByDefault: "no",
            thumbnailSelectedType: "opacity",
            startAtPlaylist: 0,
            buttonsMargins: 15,
            thumbnailMaxWidth: 350,
            thumbnailMaxHeight: 350,
            horizontalSpaceBetweenThumbnails: 40,
            verticalSpaceBetweenThumbnails: 40,
            inputBackgroundColor: "#333333",
            inputColor: "#000000",
            //playlist settings
            showPlaylistButtonAndPlaylist: "no",
            playlistPosition: "right",
            showPlaylistByDefault: "yes",
            showPlaylistName: "no",
            showSearchInput: "no",
            showLoopButton: "no",
            showShuffleButton: "no",
            showPlaylistOnFullScreen: "no",
            showNextAndPrevButtons: "yes",
            showThumbnail: "yes",
            forceDisableDownloadButtonForFolder: "yes",
            addMouseWheelSupport: "yes",
            startAtRandomVideo: "no",
            stopAfterLastVideoHasPlayed: "no",
            addScrollOnMouseMove: "no",
            randomizePlaylist: 'no',
            playlistRightWidth: 320,
            playlistBottomHeight: 250,
            startAtVideo: 0,
            maxPlaylistItems: 50,
            thumbnailWidth: 71,
            thumbnailHeight: 71,
            spaceBetweenControllerAndPlaylist: 1,
            spaceBetweenThumbnails: 1,
            scrollbarOffestWidth: 8,
            scollbarSpeedSensitivity: .5,
            playlistBackgroundColor: "#000000",
            playlistNameColor: "#FFFFFF",
            thumbnailNormalBackgroundColor: "#1b1b1b",
            thumbnailHoverBackgroundColor: "#313131",
            thumbnailDisabledBackgroundColor: "#272727",
            searchInputBackgroundColor: "#000000",
            searchInputColor: "#999999",
            youtubeAndFolderVideoTitleColor: "#FFFFFF",
            folderAudioSecondTitleColor: "#999999",
            youtubeOwnerColor: "#888888",
            youtubeDescriptionColor: "#888888",
            mainSelectorBackgroundSelectedColor: "#FFFFFF",
            mainSelectorTextNormalColor: "#FFFFFF",
            mainSelectorTextSelectedColor: "#000000",
            mainButtonBackgroundNormalColor: "#212021",
            mainButtonBackgroundSelectedColor: "#FFFFFF",
            mainButtonTextNormalColor: "#FFFFFF",
            mainButtonTextSelectedColor: "#000000",
            //controller settings
            showController: "yes",
            showControllerWhenVideoIsStopped: "yes",
            showNextAndPrevButtonsInController: "no",
            showRewindButton: "no",
            showPlaybackRateButton: "no",
            showVolumeButton: "yes",
            showTime: "no",
            showQualityButton: "no",
            showInfoButton: "no",
            showDownloadButton: "no",
            showShareButton: "no",
            showEmbedButton: "no",
            showChromecastButton: "no",
            showFullScreenButton: "yes",
            disableVideoScrubber: "no",
            showScrubberWhenControllerIsHidden: "yes",
            showMainScrubberToolTipLabel: "yes",
            showDefaultControllerForVimeo: "no",
            repeatBackground: "yes",
            controllerHeight: 42,
            controllerHideDelay: 3,
            startSpaceBetweenButtons: 7,
            spaceBetweenButtons: 8,
            scrubbersOffsetWidth: 2,
            mainScrubberOffestTop: 14,
            timeOffsetLeftWidth: 5,
            timeOffsetRightWidth: 3,
            timeOffsetTop: 0,
            volumeScrubberHeight: 80,
            volumeScrubberOfsetHeight: 12,
            timeColor: "#919191",
            youtubeQualityButtonNormalColor: "#919191",
            youtubeQualityButtonSelectedColor: "#000000",
            scrubbersToolTipLabelBackgroundColor: "#000000",
            scrubbersToolTipLabelFontColor: "#FFFFFF",
            //ads
            openNewPageAtTheEndOfTheAds: "no",
            adsButtonsPosition: "left",
            skipToVideoText: "Puedes saltar el video en: ",
            skipToVideoButtonText: "Saltar Video",
            adsTextNormalColor: "#AAAAAA",
            adsTextSelectedColor: "#FFFFFF",
            adsBorderNormalColor: "#AAAAAA",
            adsBorderSelectedColor: "#FFFFFF",
        });
    });
}

$('.fullscreen-img').on('click', function (e) {
    e.preventDefault();
    var origin_url = $(this).data('orimage');
    $('.imagepreview').attr('src', origin_url);
    $('#imagemodal').modal('show');
});
$(document).ready(function ($) {
    var button_toggler = $('.button-menu-toggler');
    var menu_toggler = $('.nav-offset');
    var nav_fake = $('.fake-background');

    if (button_toggler.length > 0) {
        button_toggler.on('click', function () {
            menu_toggler.addClass('show-nav-offset');
            nav_fake.addClass('active').parent().css('overflow', 'hidden');
        });
    }

    menu_toggler.find('.nav-offset-close').on('click', function () {
        $(this).parents(menu_toggler).removeClass('show-nav-offset');
        nav_fake.removeClass('active').parent().css('overflow', 'initial');
    });


    nav_fake.on('click', function () {
        menu_toggler.removeClass('show-nav-offset');
        $(this).removeClass('active').parent().css('overflow', 'initial');
    });

    var header_toolbar_form = $('.header-toolbar-form');
    if (header_toolbar_form.length > 0) {
        header_toolbar_form.find('.header-toolbar-form-open').on('click', function () {
            header_toolbar_form.addClass('active');
            header_toolbar_form.find('.input');
        });
        header_toolbar_form.find('.header-toolbar-form-close').on('click', function () {
            header_toolbar_form.removeClass('active');
        });
    }

    var header_toolbar_form = $('.header-toolbar-form');
    if (header_toolbar_form.length > 0) {
        header_toolbar_form.find('.header-toolbar-form-open').on('click', function () {
            header_toolbar_form.addClass('active');
            header_toolbar_form.find('.input');
        });
        header_toolbar_form.find('.header-toolbar-form-close').on('click', function () {
            header_toolbar_form.removeClass('active');
        });
    }
});
$(document).ready(function () {
    $('.subcription-form').submit(function (e) {

        e.preventDefault();

        var email = $('input[name=email]', this);
        var submit_fields = $('.subcription-fields', this);
        var message = $('.subscribe-form-message', this);
        var loading = $('.loading-fa', this);
        var csrf = $('input[name=csrfmiddlewaretoken]', this);

        if (email.val()) {

            email.prop('disabled', true);
            submit_fields.hide();
            var submit_initial_html = loading.html();
            loading.html('<i class="fa fa-spinner fa-spin fa-fw mx-auto"></i>');
            message.html('');

            var request = $.ajax({
                url: window.location.protocol + '//' + window.location.host + "/subscribe/",
                method: "POST",
                data: {
                    email: email.val(),
                    csrfmiddlewaretoken: csrf.val()
                },
            });

            request.done(function (response) {
                message.html(response.msg);
                loading.html(submit_initial_html);

                email.prop('disabled', false);
                submit_fields.show();
            });

            request.fail(function (jqXHR, textStatus) {
                message.html("Request failed: " + textStatus);
                loading.html(submit_initial_html);
                email.prop('disabled', false);
                submit_fields.show();
            });
        }
    });
});

let video = $('#video');
video.on('click', '.player-button', function (e) {
    video.addClass('hidden');
    video.one('webkitTransitionEnd', function () {
        video.addClass('d-none');
        $('#video-embed').show();
    });
});
